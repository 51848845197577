<template>
  <div>
    <h1>Servicios</h1>
    <div class="contenedor">
      <div
        v-for="serv in serviciosEsp"
        :key="serv.id"
        class="servicio"
        ref="items"
      >
        <div class="temp" v-show="!visible[serv.id]"></div>
        <transition name="escalar" appear :duration="700">
          <img
            :src="serv.imgFondo"
            class="imgFondo"
            v-show="visible[serv.id]"
          />
        </transition>
        <Deslizar
          :name="'deslizarOcultar'"
          :ocultar="true"
          :posInicial="{ x: '0', y: '50px' }"
          :posFinal="{ x: '0', y: '0' }"
          :duracion="1300"
        >
          <div v-show="visible[serv.id]">
            <h3>{{ serv.nombre }}</h3>
            <p>{{ serv.descripcion }}</p>
          </div>
        </Deslizar>
      </div>
    </div>
  </div>
</template>

<script>
import Deslizar from "@/components/Deslizar.vue";

export default {
  name: "Servicio",
  components: { Deslizar },
  data() {
    return {
      observer: {},
      visible: [],
      serviciosEsp: [
        {
          id: 0,
          nombre: "Maquinado de piezas",
          descripcion: `Servicio de maquinados en torno y fresadora de partes para mantenimiento de maquinaria industrial.
          Servicio de fabricación de piezas en serie de partes y componentes para sub ensambles (TIER2)`,
          imgFondo: require("@/assets/maquinados.png")
          // imgFondo2: `url(${require("@/assets/servicioImg.png")})`
        },
        {
          id: 1,
          nombre: "Fabricación de alta precisión",
          descripcion:
            "Realizamos maquila de piezas de alta precisión en base a su diseño desde una pieza o para producción en serie en pequeño, mediano o alto volumen.",
          imgFondo: require("@/assets/piezasPrecision2.png")
        },
        {
          id: 2,
          nombre: "Fixtures y herramentales",
          descripcion:
            "Ofrecemos diseño y desarrollo de equipo para inspección de calidad (fixtures, sistemas poka-yoke, mesas de trabajo).",
          imgFondo: require("@/assets/fixtures.png")
        },
        {
          id: 3,
          nombre: "Doblez de varilla y tubo",
          descripcion:
            "Contamos con diversas prensas para la manufactura de partes automotrices; de 20 Toneladas mecanica, 20 Toneladas, 4 Toneladas y 2 Toneladas neumática",
          imgFondo: require("@/assets/varilla.png")
        },
        {
          id: 4,
          nombre: "Soldadura y paileria ligera",
          descripcion: `Servicio de soldadura de componentes, ensambles y fixtures en aleaciones variadas;  Micro alambre MIC 100 Amperes, soldadora de electrodo 500 ACC(varilla revestida manual, amperes de corriente continua),
                        accesorio para soldadura con Argón (TIG), soldadora de electrodo 120 Amp CA (portátil)`,
          imgFondo: require("@/assets/soldadura.png")
        }
      ]
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio >= 0.5) {
            this.observer.unobserve(entry.target);
            let indice = entry.target.getAttribute("idref");
            this.visible.splice(indice, 1, true);
            // this.$emit('visible', `${this.visible}`);
          }
        });
      },
      { threshold: 0.5 }
    );

    this.$refs.items.forEach((el, index) => {
      this.observer.observe(el);
      el.setAttribute("idref", index);
      this.visible[index] = false;
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  }
};
</script>

<style lang="stylus" scoped>
.servicio
  display flex
  flex-flow column wrap
  justify-content center
  align-items center
  text-align left
  z-index 1
  margin 2em 10vw 0 10vw
  & > div
    display flex
    flex-flow column wrap
    justify-content center
    align-items center
    width 350px
    margin-top 1em
    > p
      padding-left 1em
  @media screen and (min-width: $sm)
    flex-flow column wrap
    align-items center
    justify-content flex-start
    margin 2em 2em
.imgFondo
  z-index -1
.temp
  width 350px
  height 350px
  margin-top 1em
</style>
